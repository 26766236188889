import React, { useState } from "react";
import { navigate } from "gatsby";
import useForm from "react-hook-form";
import axios from "axios";
import { Layout, Loader } from "../../components";
import { Thanks } from "../../components/brief";
import { FirstStep } from "../../components/brief/light";

const BriefLight = ({ location: { pathname } }) => {
  const lang = "en";
  const { register, errors, handleSubmit, triggerValidation, formState } =
    useForm({
      mode: "onChange",
    });
  const [isLoading, setLoading] = useState(false);
  const [step, set] = useState(1);

  const setStep = (step) => {
    set(step);
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
  };
  const onSubmit = (data, e) => {
    if (data.bot_field) return false;
    delete data.bot_field;
    setTimeout(() => {
      setLoading(true);
      axios
        .post("https://adream-mailing.herokuapp.com/send-brief-light", data)
        .then((res) => {
          setLoading(false);
          navigate("/en/brief-lite-contact");
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    }, 2000);
  };
  const stepsData = {
    step,
    setStep,
    register,
    formState,
    triggerValidation,
    errors,
  };
  return (
    <Layout
      seo={{ title: "Brief lite", href: pathname, lang: "pl" }}
      langSwitch={{
        langKey: "pl",
        langSlug: "/brief-lite/",
      }}
    >
      {isLoading && <Loader />}
      <section className="container-fluid brief_main_section steps">
        <h1>Brief – let’s get to know each other better!</h1>
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <input
            type="checkbox"
            name="bot_field"
            ref={register}
            className="bot-field"
          />
          <FirstStep {...stepsData} lang={lang} />
          {step === 2 && <Thanks />}
        </form>
      </section>
    </Layout>
  );
};

export default BriefLight;
